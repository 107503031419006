.RippleEffect {
    $root: #{&};
    position: relative;
    overflow: hidden;
    cursor: pointer;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
  
    &--circled {
      border-radius: 50%;
    }
  
    &--rounded {
      border-radius: 2rem;
    }
  
    &--inline {
      display: inline;
      text-decoration: underline;
    }
  
    &__ripple {
      width: 0;
      padding-bottom: 0;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.4);
      transform: scale(0);
      position: absolute;
      opacity: 1;
  
      #{$root}--active > & {
        animation: rippleDropOverflow 0.4s linear;
      }
  
      #{$root}--inverse & {
        background: rgba(255, 255, 255, 0.5);
      }
  
      #{$root}--circled#{$root}--active > & {
        border-radius: 50%;
        animation: rippleDrop 0.4s linear;
      }
  
      #{$root}--rounded#{$root}--active > & {
        transform: scale(1);
        border-radius: 2rem;
        animation: rippleDropFadeInOut 0.4s linear;
      }
    }
  }
  
  @keyframes rippleDrop {
    60% {
      transform: scale(1);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  
  @keyframes rippleDropOverflow {
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  
  @keyframes rippleDropFadeInOut {
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
  