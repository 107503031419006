@import "../intro/colors.scss";
@import "../intro/typography.scss";
@import "../intro/mixins.scss";

.DoubleBounce {
  $root: #{&};
  position: absolute;
  margin: auto auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  &--primary {
    #{$root}__bounce1,
    #{$root}__bounce2 {
      background-color: $pink;
      opacity: 0.8;
    }
  }

  &__bounce1,
  &__bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: bounce 1.5s infinite ease-in-out;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__bounce2 {
    animation-delay: -0.75s;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
