@import "./typography.scss";
@import "./mixins.scss";
@import "./colors.scss";

.Intro {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: white;

    &__top {
        flex-grow: 1;
        background-color: #f2f2f2;
        position: relative;
        overflow: hidden;

        > img {
            height: 115%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -40%);
        }
    }

    &__bottom {
        flex-shrink: 0;
        // padding: 4rem 2.5rem 2.5rem;
        padding: 2rem 1rem 2rem;

        @include breakpoint(medium) {
            max-width: 80rem;
            margin: 0 auto;
        }
    }

    &__title {
        text-align: center;
        @include sub-header($size: 1.8rem, $spacing: 0.05rem);
        margin-bottom: 0.4rem;
        @include max-breakpoint(small) {
            @include sub-header($size: 1.4rem, $spacing: 0.05rem);
        }
    }

    &__text {
        text-align: center;
        @include text($size: 1.2rem);
        line-height: 1.2;
        width: 100%;
        flex-shrink: 0;
        @include max-breakpoint(small) {
            @include text($size: 1rem);
            line-height: 1;
        }

        &Wrapper {
            display: flex;
            overflow: hidden;
            margin: 0 2rem 3.5rem;

            &--step2 {
                > * {
                    transform: translateX(-100%);
                }
            }

            &--step3 {
                > * {
                    transform: translateX(-200%);
                }
            }
            &--step4 {
                > * {
                    transform: translateX(-300%);
                }
            }
        }
    }

    &__nav {
        $nav: #{&};

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &Bullets {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
        }

        &Bullet {
            width: 0.8rem;
            height: 0.8rem;
            background-color: $pink;
            opacity: 0.15;
            transition: opacity linear 0.3s;
            border-radius: 50%;
            @include max-breakpoint(small) {
                width: 0.7rem;
                height: 0.7rem;
            }
            &:not(:last-child) {
                margin-right: 0.6rem;
            }

            &--active {
                opacity: 1;
            }
        }

        &Action {
            @include sub-header($size: 1.2rem);

            padding: 1rem;
            margin: -1rem;
            margin-left: auto;
            @include max-breakpoint(small) {
                padding: 0.5rem;
                font-size: 1rem;
            }
            &--prev {
                opacity: 0.3;
                margin-left: 0;
            }

            &--primary {
                color: $pink;
            }
        }
    }
}
