@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&subset=latin-ext");
@import "./colors.scss";

@mixin textTruncate() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin header($size: 2.4rem, $spacing: 0.05rem, $color: $text-black) {
  font-family: "Source Sans Pro", sans-serif;
  font-size: $size;
  letter-spacing: $spacing;
  color: $color;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

@mixin sub-header($size: 1.6rem, $spacing: 0.03rem, $color: $text-black) {
  font-family: "Source Sans Pro", sans-serif;
  font-size: $size;
  letter-spacing: $spacing;
  color: $color;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

@mixin text($size: 1.4rem, $spacing: 0.03rem, $color: $text-black) {
  font-family: "Source Sans Pro", sans-serif;
  font-size: $size;
  letter-spacing: $spacing;
  color: $color;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
