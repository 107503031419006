@import "../intro/colors.scss";
@import "../intro/typography.scss";
@import "../intro/mixins.scss";

.LoadingOverlay {
    width: 0;
    height: 0;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 1000;
    overflow: hidden;

    &--shown {
        width: 100vw;
        height: 100vh;
    }
}
